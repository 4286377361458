<template>
    <div class="nav">
        <div class="filter-nav display-flex flex-row" :class="{disabled: getSpinnerStatus}">
            <div class="filter-item">
                <button class="regular-season cursor-pointer" @click="setSeasontype('2')" :class=" { focus: `${getSeasonType}` === '2' }" :disabled="getSpinnerStatus || `${getDataScope}` === 'line'">Regular Season</button>
                <button class="playeroffs cursor-pointer" @click="setSeasontype('3')" :class="{ focus: `${getSeasonType}` === '3' }" :disabled="getSpinnerStatus || `${getDataScope}` === 'line'">Playoffs</button>
            </div>

            <div class="filter-item">
                <select name="season" id="season" class="cursor-pointer" @change="setSeason($event.target.value)" :disabled="getSpinnerStatus || `${getDataScope}` === 'line'">
                    <option :value="season.unformatted" v-for="season,index in getSeasonData" :key="index" :selected = "`${getSeason}` === 'season.unformatted'">{{season.formatted}}</option>
                </select>
            </div>
            <div class="filter-item">
                <select name="" class="cursor-pointer" @change="setDataScope($event.target.value)" :disabled="getSpinnerStatus">
                    <option value="overall">Overall</option>
                    <option value="trend">Trend</option>
                    <option value="line">Lines</option>
                </select>
            </div>
            <div class="filter-input" v-if="`${getDataScope}` === 'trend'" :class="[ `${getDataScope}` === 'trend' ? 'show-input' : 'hide-input' ]">
                <div class="gamelog-input display-flex flex-row">
                    <span class="material-icons vertical-middel text-center cursor-pointer" :disabled="disabled" @click="minusNumberOfGames">remove</span>
                    <p class="vertical-middel">Last</p>
                    <input type="number" inputmode="numeric" class="text-center" :value="`${getNumberOfGames}`" @keyup="setNumberOfGames($event.target.value)" @keydown="validateInputNumber($event)" @focus="$event.target.select()">
                    <p class="vertical-middel">Games</p>
                    <span class="material-icons vertical-middel text-center cursor-pointer" @click="plusNumberOfGames">add</span>
                </div>
            </div>
        </div>
        <hr class="horizontal-line filter-line">
    </div>
</template>

<script>
import { useStore } from 'vuex'
import { computed, ref } from 'vue'

export default {
    name: 'FilterNavbar',

    setup() {
        const store = useStore();
        const disabled = ref(false);

        // get data from index.js state to set focus on buttons
        const getSeasonType = computed(() => {
            return store.state.seasonType
        })
        const getSeason = computed(() => {
            return store.state.season
        })
        const getDataScope = computed(() => {
            return store.state.dataScope
        })
        const getNumberOfGames = computed(() => {
            return store.state.numberOfGames
        })
        const getButtonStatus = computed(() => {
            return store.state.buttonStatus
        })

        const getSpinnerStatus = computed(() => {
            return store.state.spinner
        })

        const getSeasonData = computed(() => {
            return store.getters.getSeasons
        })

        // handle click event
        // calling actions in index.js
        const setSeasontype = (type) => {
            // load spinner and disable buttons      
            store.dispatch('setSpinner', true)
            store.dispatch('setButtons', true)
            store.dispatch('setSeasonType', type)
            store.dispatch('getPicks')
            store.dispatch('getGameLogs')
        }
        const setSeason = (season) => {
            store.dispatch('setSpinner', true)
            store.dispatch('setButtons', true)
            store.dispatch('setSeason', season)
            store.dispatch('getPicks')
            store.dispatch('getGameLogs')
        }
        const setDataScope = (dataScope) => {
            store.dispatch('setDataScope', dataScope)
        }
        const minusNumberOfGames = () => {
            if(getNumberOfGames.value <= 1) {
                disabled.value = true
            }
            else {
                store.dispatch('minusNumberOfGames')
            }
        }
        const plusNumberOfGames = () => {
            store.dispatch('plusNumberOfGames')
            disabled.value = false
        }
        // user type in number in the input value
        // no zero, empty value, and other characters
        const setNumberOfGames = (number) => {
            if(number > 0 || number.length > 0 ) {
                store.dispatch('setNumberOfGames', number)
            }
        }

        const validateInputNumber = (e) => {
            const invalidChars = ["-", "+", "e", "E", "."];
            if (invalidChars.includes(e.key)) {
                e.preventDefault();
            }
        }

        return { disabled, getSeasonType, getSeason, getDataScope, getNumberOfGames, getButtonStatus, getSpinnerStatus, getSeasonData, setSeasontype, setSeason, setDataScope, minusNumberOfGames, plusNumberOfGames, setNumberOfGames, validateInputNumber }
    }

 
}
</script>

<style scoped>
.filter-line {
    border-top: 1px solid var(--grey-5);
    margin-top: 15px;
}
.filter-nav {
    flex-direction: column;
    gap: 20px;
}
.filter-nav.disabled {
    opacity: 0.4;
}
.filter-item {
    text-align: center;
    width: 100%;
}
.filter-item button {
    background-color: var(--white);
    padding: 12.5px;
    border: 1px solid var(--red-6);
    font-size: 16px;
    width: 50%;
    color: var(--grey-5);
}
.filter-item button.focus {
    background-color: var(--red-6);
    color: var(--white);
    pointer-events: none;
}
.filter-item select {
    padding: 9.5px;
    border: 1px solid var(--red-6);
    font-size: 16px;
    width: 100%;
    color: var(--grey-5);
}
.filter-input .gamelog-input {
    justify-content: center;
}
.filter-item button:disabled, .filter-item select:disabled {
    opacity: 0.4;
    cursor: default;
}
.filter-input span {
    background-color: var(--grey);
    padding: 8px;
}
.filter-input p {
    margin: 0 7px;
    line-height: 40px;
}
/* Chrome, Safari, Edge, Opera */
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}
.filter-input input {
    width: 26px;
    height: 26px;
    padding: 5px;
    font-size: 16px;
}
.filter-input.show-input {
    /* animation: fadeIn ease .5s; */
    display: inline-block;
}
.filter-input.hide-input {
    /* animation: fadeOut ease .5s; */
    display: none;
}
.filter-input span[disabled=true] {
    opacity: 0.4;
}

/* dark theme */
.dark-theme .filter-item button, .dark-theme .filter-item select {
    background-color: #5c5c5c;
    color: var(--white);
}
.dark-theme .filter-item button.focus {
    background-color: #b60d2980;
    border: 1px solid #b60d2980;
}
.dark-theme .filter-input {
    color: var(--white);
}
.dark-theme .filter-input .material-icons {
    background-color: #5c5c5c;
}
.dark-theme .filter-input input {
    color: #333333;
}

@keyframes fadeIn {
    0% {
        opacity:0;
    }
    100% {
        opacity:1;
    }
}
@keyframes fadeOut {
    0% {
        opacity:1;
    }
    100% {
        opacity:0;
    }
}

@media screen and (min-width: 768px) {
    .filter-nav {
        flex-direction: row;
    }
    .filter-item {
        width: 48%;
    }
}

@media screen and (min-width: 992px) {
    .filter-item {
        width: auto;
    }
    .filter-item button, .filter-item select {
        width: auto;
    }
    .filter-item button {
        padding: 10px;
    }
}

@media print {
    .filter-nav {
        flex-direction: row;
    }
    .filter-item {
        width: 40%;
    }
    .filter-item:nth-child(2) {
        width: 20%;
    }
    .filter-item:nth-child(3) {
        width: 30%;
    }

}

</style>