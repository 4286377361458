<template>
  <Header />
  <router-view />
  <Footer />
</template>

<script>
import Header from './components/Header'
import Footer from './components/Footer'

export default {
  components: {
    Header,
    Footer
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');

:root {
  --white: #FFFFFF;
  --grey-0: #F1F1F1;
  --grey-1: #E9E9E9;
  --grey-2: #D9D9D9;
  --grey-3: #A6A6A6;
  --grey-4: #737373;
  --grey-5: #404040;
  --grey-6: #0D0D0D;
  --red-1:#F2DEDE;
  --red-2: #EBCCD1;
  --red-3: #d88e8e;
  --red-4: #A94442;
  --red-5: #B60D29;
  --red-6: #8C0303;
  --blue-1: #1D9BF0;
  --blue-2: #007bff;
  --blue-3: #1D5ACC;
  --blue-4: #0056b3;

}
/* reset styles */
* {
  color: inherit;
  margin: 0;
}
html, body {
  height: 100%;
}
html {
  scroll-behavior: smooth;
}
body {
  font-family: Poppins;
}
#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
ul {
  padding: 0;
  list-style-type: none;
}
a {
  text-decoration: none;
}
img {
  max-width: 100%;
}
button {
  border: 0;
  background-color: var(--white);
}
.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 0 auto;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px;
  box-sizing: border-box;
}
main section {
  margin: 1.5rem 0;
}
.section-title {
  justify-content: flex-start;
  margin-bottom: 2rem;
}
.section-title .section-title__label {
    box-shadow: inset 0 -6px var(--red-6);
    padding-bottom: 3px;
    font-size: 1.7rem;
    color: var(--grey-6);
}
.display-flex {
    display: flex;
}
.flex-row {
    flex-flow: row wrap;
}
.flex-grow-1 {
    flex-grow: 1;
}
.vertical-middle {
    vertical-align: middle;
}
.justify-content-space-between {
    justify-content: space-between;
}
.justify-center {
  justify-content: center;
}
.item-center {
  align-items: center;
}
.text-center {
  text-align: center;
}
.text-left {
    text-align: left;
}
.cursor-pointer {
    cursor: pointer;
}
.font-7 {
  font-weight: 700;
}
.error-sign {
  color: #d88e8e;
}
.error-message {
  font-size: 1.2rem;
  font-weight: 600;
}
.site-status {
  margin-top: 2rem;
}

/* dark mode */
.dark-theme {
  background-color: #1b1b1b;
}
.dark-theme .global-header .logo p {
  color: var(--white);
}
.dark-theme .global-header {
    box-shadow: 0 1px 3px rgb(255 255 255 / 50%), 0 2px 2px rgb(0 0 0 / 6%), 0 0 2px rgb(0 0 0 / 7%);
}
.dark-theme .global-header .button .icon-bar {
  background: var(--white);
}
.dark-theme .disclaimer {
  color: var(--white);
}
.dark-theme .error-message {
  color: #dedede;
}
.dark-theme table {
  background-color: #4c4c4c;
}
/*  */
.dark-theme .players .sticky-col a {
  color: #aac8e4;
}
.dark-theme table th, .dark-theme table td {
  color: #dedede;
}
.dark-theme thead tr {
  border-bottom: 2px solid #959595;
}
.dark-theme th, .dark-theme td {
  border-left: 1px solid #5c5c5c;
}
.dark-theme .player-list .player-list-header {
  background-color: #b60d2980;
  border: 1px solid #b60d2980;
}
.dark-theme .player-table {
  border: 1px solid #b60d2980;
}
.dark-theme .players .player-name {
  border-right: 1px solid #959595;
}
.dark-theme .players img {
  padding: 1px;
  background-color: var(--white);
  border-radius: 15px;
  margin-right: 5px;
}
.dark-theme .section-title .section-title__label {
  box-shadow: inset 0 -6px var(--red-5);
  color: var(--white);
}
.dark-theme footer {
  background-color: var(--dark-6);
  color: var(--white);
  border-top: 1px solid rgb(255 255 255 / 50%);
}
.dark-theme .legend .legend-table .legend-column {
  color: #dedede;
}


@media screen and (min-width: 768px) {
    .row {
      display: flex;
      flex-flow: row wrap;
    } 
}

@media screen and (min-width: 1200px) {
  .container {
    max-width: 960px;
  }
}

@media print {
  .navigation {
    display: none;
  }
}
</style>