<template>  
    <div :id="`nitroPay-Ad-mobile-${adid}`" class="ads"></div>
    <div :id="`nitroPay-Ad-desktop-${adid}`" class="ads"></div>
</template>

<script>
import { onMounted } from "vue";

export default {
    name: 'AdComponent',
    props:['adid'],
    setup(props) {
        const ad = (adid) => {
            window['nitroAds'].createAd('nitroPay-Ad-mobile-'+adid, {
            "refreshLimit": 0,
            "refreshTime": 30,
            "renderVisibleOnly": false,
            "refreshVisibleOnly": true,
            "sizes": [
                [
                "320",
                "50"
                ]
            ],
            "report": {
                "enabled": true,
                "icon": true,
                "wording": "Report Ad",
                "position": "top-right"
            },
            "mediaQuery": "(min-width: 320px) and (max-width: 767px)"
            });

            window['nitroAds'].createAd('nitroPay-Ad-desktop-'+adid, {
            "refreshLimit": 0,
            "refreshTime": 30,
            "renderVisibleOnly": false,
            "refreshVisibleOnly": true,
            "sizes": [
                [
                "728",
                "90"
                ]
            ],
            "report": {
                "enabled": true,
                "icon": true,
                "wording": "Report Ad",
                "position": "top-right"
            },
            "mediaQuery": "(min-width: 1025px)"
            });           
        
        }        

        onMounted(() => {
            ad(props.adid)
        })
    }
}
</script>

<style scoped>
.ads {
    margin-top: 30px;
}
</style>