<template>
    <table class="games">
        <tr style="border-bottom: 1px solid #7e7e7e;">
            <th>Away</th>
            <th>Goals</th>
            <th>Home</th>
            <th>Goals</th>
            <th>Status</th>
            <th>Start Time</th>
        </tr>
        <tr :key="game.homeTeam" v-for="game in games">
            <td>{{ game.awayTeam }}</td>
            <td>{{ game.awayGoals }}</td>
            <td>{{ game.homeTeam }}</td>
            <td>{{ game.homeGoals }}</td>
            <td>{{ game.status }}</td>
            <td>{{ game.startTime }}</td>
        </tr>
    </table>
</template>

<script>
export default {
    name: 'Games',
    props: ['games'],
    setup() {}
}
</script>

<style scoped>
table.games {
    border-collapse: collapse;
    border: none;
    border-spacing: 0;
    width: auto;
    text-align: left;
}
.games th, .games td {
    border: 0;
}
.games th:nth-child(1), .games th:nth-child(3) {
    width: 55px;
}
.games th:nth-child(2), .games th:nth-child(4) {
    width: 45px;
}
.games th:nth-child(5) {
    width: 90px;
}
.dark-theme table {
    background-color: transparent;
}
</style>