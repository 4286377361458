<template>
  <main class="container body-content">
      <section class="updates">
          <div class="section-title display-flex">
              <h1 class="section-title__label">Updates</h1>
          </div>
          <section class="content">
              <section>
                  <article class="blogs">
                      <h2>New Feature! Projected Lineups</h2>
                      <time class="date" datetime="2024-05-03">May 3, 2024</time>
                      <div class="article-content">
                          <ul>
                              <li>Now you can see which even strength line and powerplay line players are on in the EV Line and PP Line columns.</li>
                              <li>New "Lines" view also includes both even strength and powerplay linemates.</li>
                              <li>These are PROJECTED lines at the time you view them, they can change until right before the game starts!</li>
                              <li>Even strength line is which line they normally play on at 5on5. If a player does not have a line indicated they may be scratched and not playing. The scratch warning has been removed as it is indicated by them not being on a line.</li>
                              <li>Power play line is which line they play on during powerplays. Not all players play on the powerplay.</li>
                              <li>Normally the best players play on the first line then second and so on but that is not always the case, so checking their linemates can provide more insight.</li>
                          </ul>
                      </div>
                  </article>
              </section>
                <section>
                    <article class="blogs">
                        <h2>NHL API Change</h2>
                        <time class="date" datetime="2023-12-12">December 12, 2023</time>
                        <div class="article-content">
                            <ul>
                                <li>Restore all functionality after NHL API changes.</li>
                            </ul>
                        </div>
                    </article>
                </section>
                <section>
                    <article class="blogs">
                        <h2>Stability/Performance Update</h2>
                        <time class="date" datetime="2023-04-17">April 17, 2023</time>
                        <div class="article-content">
                            <ul>
                                <li>Significantly improve performance and stability during peak usage periods.</li>
                            </ul>
                        </div>
                    </article>
                </section>
                <section>
                    <article class="blogs">
                        <h2>Minor Update</h2>
                        <time class="date" datetime="2023-02-21">February 21, 2023</time>
                        <div class="article-content">
                            <ul>
                                <li>More stability fixes.</li>
                                <li>Added email to feedback form because people ask questions but don't leave their email so we can't reply :(</li>
                            </ul>
                        </div>
                    </article>
                </section>
                <!--
                    <section>
                        <article class="blogs">
                            <h2>Scoreboard</h2>
                            <time class="date" datetime="2023-02-06">February 6, 2023</time>
                            <div class="article-content">
                                <ul>
                                    <li>New Scoreboard Page.  Live view of games and picks who scored.</li>
                                    <li>Upgraded server.  Will hopefully resolve the 'overloaded server' issue.  (update: still having problems with this)</li>
                                    <li>More player warnings (ie sent to minors/AHL).</li>
                                    <li>Nicer error handling.</li>
                                    <li>Various performance improvements.</li>
                                    <li>Various bug fixes.</li>
                                </ul>
                            </div>
                        </article>
                    </section>
                    <section>
                        <article class="blogs">
                            <h2>Dark mode</h2>
                            <time class="date" datetime="2022-12-20">December 20, 2022</time>
                            <div class="article-content">
                                <ul>
                                    <li>Dark Mode</li>
                                    <li>Crossmatch Player and team to warn of players who are not on the Tim Horton's team</li>
                                    <li>Added more content to FAQ.</li>
                                </ul>
                            </div>
                        </article>
                    </section>
                        <section>
                            <article class="blogs">
                                <h2>Updates</h2>
                                <time class="date" datetime="2022-12-13">December 13, 2022</time>
                                <div class="article-content">
                                    <ul>
                                        <li>Changed highlight for injured players to make it accessible for people who are colourblind.</li>
                                        <li>Fixed game logs not loading immediately after new picks are available.</li>
                                        <li>Fixed long time out when picking invalid stats period.</li>
                                    </ul>
                                </div>
                            </article>
                        </section>
        -->
                <section class="road-map">
                    <h2>Major Feature Roadmap</h2>
                    <em>Thanks for all your feedback and support. Please remember this is a hobby project so there will be long gaps between updates!</em>
                    <div class="article-content">
                        <ul>
                            <li>
                                History
                                <ul>
                                    <li>Previous available players.</li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </section>
            </section>
        </section>
</main>
</template>

<script>

export default {
  name: "Updates",
    meta: {
        title : "Tim Horton's Hockey Challenge Helper - Updates"
    },
};
</script>

<style scoped>
.updates h2 {
  font-size: 1.2rem;
  color: var(--grey-6);
}
.updates .date {
  font-size: 0.9rem;
  color: var(--grey-4);
}
.updates .article-content {
  margin-top: 0.5rem;
}
.updates ul li {
  margin-left: 2rem;
}
.updates .blogs ul li {
  list-style: disc;
}
.updates .road-map ul li {
  list-style: decimal;
}
.updates .road-map ul ul li {
  list-style: lower-latin;
  margin-left: 2rem;
}

.dark-theme .updates h2, .dark-theme em {
  color: var(--white);
}
.dark-theme .updates .date {
  color: var(--grey-2);
}
.dark-theme .updates .article-content {
  color: #dedede;
}
</style>